import React from 'react';
import NextImage from 'next/image';

import Button, { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import Grid, { Col } from 'Components/Grid';

import waveSvg from 'Public/graphics/waves/promo-point/general/wave-promo-general.svg';
import bgWave from 'Public/graphics/waves/promo-point/general/wave-promo-general-bg.svg';

import {
    Wrapper,
    Inner,
    Heading,
    Text,
    ButtonContainer,
    Background,
    WaveWrapper,
    ImageWrapper,
    Container
} from './General.styled';

export interface Props {
    text: string;
    heading: string;
    link?: ArrowLinkButtonProps;
    image?: {
        src: string;
        alt?: string;
    };
    textColor?: string;
    backgroundColor?: string;
    showSectionBackground?: boolean;
    showSectionWave?: boolean;
}

const General: React.FC<Props> = props => {
    const {
        heading,
        text,
        link,
        image,
        textColor,
        backgroundColor,
        showSectionBackground = true,
        showSectionWave = true
    } = props;

    return (
        <Wrapper $showSectionBackground={showSectionBackground}>
            {showSectionWave && (
                <Background>
                    <NextImage src={bgWave} alt="" fill style={{ objectFit: 'cover' }} />
                </Background>
            )}
            <Grid>
                <Col>
                    <Container
                        style={
                            {
                                '--bg-color': backgroundColor,
                                '--text-color': textColor
                            } as React.CSSProperties
                        }
                    >
                        {image && (
                            <ImageWrapper $image={!!image}>
                                <NextImage
                                    src={image.src}
                                    alt={image.alt ? image.alt : ''}
                                    fill
                                    style={{ objectFit: 'cover' }}
                                />
                            </ImageWrapper>
                        )}
                        <Inner $image={!!image}>
                            <Background>
                                <WaveWrapper $image={!!image}>
                                    <NextImage
                                        src={waveSvg}
                                        alt=""
                                        fill
                                        style={{ objectFit: 'cover' }}
                                    />
                                </WaveWrapper>
                            </Background>
                            <Heading>{heading}</Heading>
                            <Text>{text}</Text>
                            {link && (
                                <ButtonContainer>
                                    <Button
                                        {...link}
                                        variant={link.variant ? link.variant : 'petrolBlue'}
                                    />
                                </ButtonContainer>
                            )}
                        </Inner>
                    </Container>
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default General;
