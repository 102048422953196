import styled, { css } from 'styled-components';

interface ImageBoolean {
    $image: boolean;
}

export const Wrapper = styled.div<{ $showSectionBackground: boolean }>`
    ${({ theme, $showSectionBackground }) => css`
        position: relative;
        overflow: hidden;

        ${$showSectionBackground &&
        css`
            padding-bottom: 1.5rem;

            @media ${theme.breakpoints.large.media} {
                padding-bottom: 3rem;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                background-color: ${theme.colors.secondary.tints[1].hex};
                width: 100%;
                height: 6rem;
                z-index: 0;

                @media ${theme.breakpoints.large.media} {
                    height: 10rem;
                }
            }
        `}
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        background-color: var(--bg-color, ${theme.colors.accents[10].hex});
        border-radius: 0.5rem;
        transform: translateZ(0);
        position: relative;
        overflow: hidden;
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        color: var(--text-color, ${theme.colors.primaryContrast.hex});
        margin: 0 0 1rem;
    `}
`;

export const Text = styled.p`
    ${({ theme }) => css`
        margin: 0;
        color: var(--text-color, ${theme.colors.primaryContrast.hex});

        @media ${({ theme }) => theme.breakpoints.medium.media} {
            padding: 0;
        }
    `}
`;

export const ButtonContainer = styled.div`
    margin-top: 1rem;
`;

export const Background = styled.div`
    ${({ theme }) => css`
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        z-index: -1;

        @media ${theme.breakpoints.medium.media} {
            display: block;
        }
    `}
`;

export const WaveWrapper = styled.div<ImageBoolean>`
    ${({ theme, $image }) => css`
        width: 112.5rem;
        height: 29rem;
        position: absolute;
        right: ${$image ? '-73rem' : '-80rem'};
        bottom: ${$image ? '19rem' : '15rem'};
        opacity: 0.3;
        transform: ${$image ? 'rotate(316deg)' : 'rotate(-37deg)'} scale(1.2);
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
        }
    `}
`;

export const Inner = styled.div<ImageBoolean>`
    ${({ theme, $image }) => css`
        position: relative;
        z-index: 1;
        padding: 1.5rem 1rem;

        @media ${theme.breakpoints.medium.media} {
            padding: 1.5rem 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 3rem;
            max-width: ${$image ? '60%' : '80%'};
        }
    `}
`;

export const ImageWrapper = styled.div<ImageBoolean>`
    ${({ theme, $image }) => css`
        position: absolute;
        z-index: 2;
        right: 0;
        width: 40%;
        height: 100%;
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: ${$image ? 'block' : 'none'};
        }
    `}
`;
